import React from "react";
import {useContext} from "react";
import Context from "./context";
import {Redirect, Route} from "react-router-dom";

const ProtectedRoute = ({component: Component, ...rest}) => {
    let {state} = useContext(Context);
    return (
        <Route render={props => !state.isAuth ? <Redirect to="/login"/> : <Component{...props}/>} {...rest} />
    )
}

export default ProtectedRoute