import React, {useContext} from "react";
import {withStyles} from "@material-ui/core/styles";
import GoogleLogin from "react-google-login";
import {GraphQLClient} from "graphql-request";
import Context from "../../context";
import {Typography} from "@material-ui/core";
import {ME_QUERY} from "../../graphql/queries";
import {BASE_URL} from "../../client";

const Login = ({classes}) => {
    const {dispatch} = useContext(Context);
    const onSuccess = async (googleUser) => {
        try {
            const idToken = googleUser.getAuthResponse().id_token;
            const client = new GraphQLClient(BASE_URL, {
                headers: {
                    authorization: idToken
                }
            });
            const {me} = await client.request(ME_QUERY);
            dispatch({type: "LOGIN_USER", payload: me})
            dispatch({type: "IS_LOGGED_ID", payload: googleUser.isSignedIn()})
        } catch (err) {
            onFailure(err);
        }
    }

    const onFailure = err => {
        console.error("Error logging in", err)
        dispatch({type: "IS_LOGGED_ID", payload: false})
    }

    return (
        <div className={classes.root}>
            <Typography
                component="h1"
                variant="h3"
                gutterBottom
                noWrap
                style={{color: "rgb(66, 133, 244)"}}
            >
                Welcome
            </Typography>
            <GoogleLogin
                isSignedIn={true}
                onSuccess={onSuccess}
                onFailure={onFailure}
                clientId={"430415833479-l985htcaifp6m8p3hq3gtnmc8a87btko.apps.googleusercontent.com"}
                buttonText="Login with Google"
                theme="dark"
            />
        </div>
    )
};

const styles = {
    root: {
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center"
    }
};

export default withStyles(styles)(Login);
