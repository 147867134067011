import React, {useContext} from "react";
import {withStyles} from "@material-ui/core/styles";
import {GoogleLogout} from "react-google-login";
import {Typography} from "@material-ui/core";
import {ExitToApp} from "@material-ui/icons";
import Context from "../../context";
// import ExitToApp from "@material-ui/icons/ExitToApp";
// import Typography from "@material-ui/core/Typography";
import {unstable_useMediaQuery as useMediaQuery} from "@material-ui/core/useMediaQuery";

const Signout = ({classes}) => {
    const mobileSize = useMediaQuery('(max-width: 650px)');
    let { dispatch } = useContext(Context);
    const onSingout = () => {
        dispatch({ type: "SIGNOUT_USER"})
        console.log("Singout")
    }

    return (
        <GoogleLogout
            onLogoutSuccess={onSingout}
            render={({onClick}) => (
                <span className={classes.root} onClick={onClick}>
            <Typography
                style={{ display: mobileSize?"none":"block"}}
                variant="body1"
                className={classes.buttonText}
            >
               Signout
            </Typography>
            <ExitToApp className={classes.buttonIcon}/>
          </span>
            )}
        />
    );
}

const styles = {
    root: {
        cursor: "pointer",
        display: "flex"
    },
    buttonText: {
        color: "orange"
    },
    buttonIcon: {
        marginLeft: "5px",
        color: "orange"
    }
};

export default withStyles(styles)(Signout);
