import {GraphQLClient} from "graphql-request";
import {useEffect, useState} from "react";

export const BASE_URL = process.env.NODE_ENV === "production" ? "https://geopins-create1st.herokuapp.com/" : "http://localhost:4000/graphql"

const useClient = () => {
    const [idToken, setIdToken] = useState("");
    useEffect(() => {
        const token = window.gapi.auth2
            .getAuthInstance()
            .currentUser.get()
            .getAuthResponse()
            .id_token;
        setIdToken(token)
    }, []);

    return new GraphQLClient(BASE_URL, {
        headers: {
            authorization: idToken
        }
    });
}

export default useClient