import React, {useContext, useState} from "react";
import {Divider, IconButton, InputBase, withStyles} from "@material-ui/core";
import {Clear, Send} from "@material-ui/icons";
import useClient from "../../client";
import Context from "../../context";
import {CREATE_COMMENT_MUTATION} from "../../graphql/mutations";
// import InputBase from "@material-ui/core/InputBase";
// import IconButton from "@material-ui/core/IconButton";
// import ClearIcon from "@material-ui/icons/Clear";
// import SendIcon from "@material-ui/icons/Send";
// import Divider from "@material-ui/core/Divider";

const CreateComment = ({classes}) => {
    const [comment, setComment] = useState("");
    const client = useClient();
    //const {state, dispatch} = useContext(Context);
    const {state} = useContext(Context);

    const handleSubmitComment = async () => {
        const variable = {
            pinId: state.currentPin._id,
            text: comment
        };
        await client.request(CREATE_COMMENT_MUTATION, variable);
        // const {createComment} = await client.request(CREATE_COMMENT_MUTATION, variable);
        // dispatch({type: "CREATE_COMMENT", payload: createComment});
        setComment("")
    }

    return (
        <>
            <form className={classes.form}>
                <IconButton
                    onClick={() => setComment("")}
                    disabled={!comment.trim()}
                    className={classes.clearButton}
                >
                    <Clear/>
                </IconButton>
                <InputBase
                    multiline={true}
                    className={classes.input}
                    placeholder="Add Comment"
                    value={comment}
                    onChange={e => setComment(e.target.value)}
                />
                <IconButton
                    onClick={handleSubmitComment}
                    disabled={!comment.trim()}
                    className={classes.sendButton}
                >
                    <Send/>
                </IconButton>
            </form>
            <Divider/>
        </>
    );
};

const styles = theme => ({
    form: {
        display: "flex",
        alignItems: "center"
    },
    input: {
        marginLeft: 8,
        flex: 1
    },
    clearButton: {
        padding: 0,
        color: "red"
    },
    sendButton: {
        padding: 0,
        color: theme.palette.secondary.dark
    }
});

export default withStyles(styles)(CreateComment);
